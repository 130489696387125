import React from "react";
import Image from "next/image";
import Link from "next/link";

import productImg from "@src/static/img/product-img.png";
import productImg2 from "@src/static/img/product-img-2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCurrency } from "../CurrencyContext";
import "swiper/css";

const Productcarousel = ({checkedProducts}) => {
  const { currency } = useCurrency();
  if(checkedProducts && checkedProducts.length > 0 )
  return (
    <div className="productListBoxSection carouselProductBox mt-4">
      <Swiper
        spaceBetween={13}
        slidesPerView={2.2}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        
      >
        {checkedProducts.map((product, index)=>(
          <SwiperSlide key={index}>
            <div className="">
              <div className="rounded-xl overflow-hidden bg-gray-200 productImgMain h-32 w-full">
                <Image 
                  className="w-full h-full object-contain mx-auto"
                  src={
                    product?.images?.edges[0]?.node?.originalSrc
                    ? product.images.edges[0].node.originalSrc
                    : productImg
                  } 
                  alt=""
                  width="100"
                  height="100"
                />
              </div>
              <div className="text-left rtl:text-right">
                <h2 className="font-medium text-xs capitalize mt-1.5">{product?.title}</h2>
                <p className="text-xs font-normal">{`Price ${product.variants.edges[0].node.price} ${currency}`}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
  return (
    <>
      <div className="productListBoxSection carouselProductBox mt-4">
        <Swiper
          spaceBetween={20}
          slidesPerView={2.5}
          autoplay={{ delay: 3000, disableOnInteraction: true }}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          
        >
          <SwiperSlide>
            <div className="">
              <div className="">
                <Image
                  className="h-auto w-full mx-auto"
                  src={productImg2}
                  alt=""
                />
              </div>
              <div className="">
                <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                <p className="text-sm">Price$</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <div className="">
                <Image
                  className="h-auto w-full mx-auto"
                  src={productImg2}
                  alt=""
                />
              </div>
              <div className="">
                <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                <p className="text-sm">Price$</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <div className="">
                <Image
                  className="h-auto w-full mx-auto"
                  src={productImg2}
                  alt=""
                />
              </div>
              <div className="">
                <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                <p className="text-sm">Price$</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <div className="">
                <Image
                  className="h-auto w-full mx-auto"
                  src={productImg2}
                  alt=""
                />
              </div>
              <div className="">
                <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                <p className="text-sm">Price$</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <div className="">
                <Image
                  className="h-auto w-full mx-auto"
                  src={productImg2}
                  alt=""
                />
              </div>
              <div className="">
                <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                <p className="text-sm">Price$</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};
export default Productcarousel;
