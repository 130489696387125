import React from "react";
import Image from 'next/image'
import Link from "next/link"

import productImg from "@src/static/img/product-img.png"
import productImg2 from "@src/static/img/product-img-2.png"
import { useCurrency } from "../CurrencyContext";

const Productgrid = ({checkedProducts}) => {
  const {currency} = useCurrency();
  return (
    checkedProducts && checkedProducts.length > 0 ? 
    <div className="productListBoxSection gridBoxList mt-3">
      <div className="grid gap-3 grid-cols-2">
        {checkedProducts.map((product, index)=>(
          <div className="gridProductSectionMain rounded-2xl last:mb-3" key={index}>
            <div className="rounded-xl overflow-hidden bg-gray-200 h-32 w-full">
              <Image 
                className="h-full w-full object-contain"
                src={
                  product?.images?.edges[0]?.node?.originalSrc
                  ? product.images.edges[0].node.originalSrc
                  : productImg
                } 
                alt=""
                width="100"
                height="100"
              />
            </div>
            <div className="text-left rtl:text-right">
                <h2 className="font-medium text-sm mt-1.5 text-xs">{product?.title}</h2>
                <p className="text-xs font-normal">{`Price ${product.variants.edges[0].node.price} ${currency}`}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  :
    <div className="productListBoxSection gridBoxList">
      <div className="flex flex-wrap">
        <div className="mt-4 w-1/2">
          <div className="mr-2 rtl:ml-2 rtl:mr-0">
            <Image className="h-auto w-full mx-auto"  src={productImg} alt=""/>
          </div>
          <div className="">
            <h2 className="font-medium text-sm mt-1.5">Item name</h2>
            <p className="text-sm">Price$</p>
          </div>
        </div>
        <div className="mt-4 w-1/2">
          <div className="mr-2 rtl:ml-2 rtl:mr-0">
          <Image className="h-auto w-full mx-auto"  src={productImg} alt=""/>
          </div>
          <div className="">
            <h2 className="font-medium text-sm mt-1.5">Item name</h2>
            <p className="text-sm">Price$</p>
          </div>
        </div>
        <div className="mt-4 w-1/2">
          <div className="mr-2 rtl:ml-2 rtl:mr-0">
          <Image className="h-auto w-full mx-auto"  src={productImg} alt=""/>
          </div>
          <div className="">
            <h2 className="font-medium text-sm mt-1.5">Item name</h2>
            <p className="text-sm">Price$</p>
          </div>
        </div>
        <div className="mt-4 w-1/2">
          <div className="mr-2 rtl:ml-2 rtl:mr-0">
          <Image className="h-auto w-full mx-auto"  src={productImg} alt=""/>
          </div>
          <div className="">
            <h2 className="font-medium text-sm mt-1.5">Item name</h2>
            <p className="text-sm">Price$</p>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Productgrid;