import React from "react";
import Image from 'next/image'
import Link from "next/link"

import productImg from "@src/static/img/product-img.png"
import productImg2 from "@src/static/img/product-img-2.png"
import { useCurrency } from "../CurrencyContext";

const Productlist = ({checkedProducts}) => {
    const {currency} = useCurrency();
    if(checkedProducts && checkedProducts.length > 0 )
        return (
            <div className="productListBoxSection">
                {checkedProducts.map((product, index)=>(
                <div className="flex items-center mt-4 relative h-16 productListContentMain rounded-2xl last:mb-4" key={index}>
                    <div className="h-16 w-16 rounded-xl overflow-hidden bg-gray-200 absolute left-0 top-0">
                        <Image 
                            className="h-full w-full object-contain mx-auto"
                            src={
                                product?.images?.edges[0]?.node?.originalSrc
                                ? product.images.edges[0].node.originalSrc
                                : productImg
                            } 
                            alt=""
                            width="100"
                            height="100"
                        />
                    </div>
                    <div className="truncate text-left">
                        <h2 className="font-medium text-sm truncate">{product?.title}</h2>
                        <p className="text-sm font-normal">{`Price ${product.variants.edges[0].node.price} ${currency}`}</p>
                    </div>
                </div>
            ))}
            </div>
        )
    return (
        <div className="productListBoxSection">
            <div className="flex items-center mt-4">
                <div className="mr-2 rtl:mr-0 rtl:ml-2">
                    <Image className="h-16 w-16 mx-auto"  src={productImg2} alt=""/>
                </div>
                <div className="">
                    <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                    <p className="text-sm">Price$</p>
                </div>
            </div>

            <div className="flex items-center mt-4">
                <div className="mr-2 rtl:mr-0 rtl:ml-2">
                    <Image className="h-16 w-16 mx-auto"  src={productImg} alt=""/>
                </div>
                <div className="">
                    <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                    <p className="text-sm">Price$</p>
                </div>
            </div>

            <div className="flex items-center mt-4">
                <div className="mr-2 rtl:mr-0 rtl:ml-2">
                    <Image className="h-16 w-16 mx-auto"  src={productImg} alt=""/>
                </div>
                <div className="">
                    <h2 className="font-medium text-sm mt-1.5">Item name</h2>
                    <p className="text-sm">Price$</p>
                </div>
            </div>
        </div>
    )
};
export default Productlist;